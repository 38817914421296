import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import React, { useState, useEffect } from "react"
import CloseIcon from "@material-ui/icons/Close"
import DrawerSelect from "./utils/DrawerSelect"
import DrawerTextField from "./utils/DrawerTextField"
import DrawerButton from "./utils/DrawerButton"
import {
  actions,
  createBooking,
  updateBooking,
} from "../../../redux/slices/scheduleSlice"
import apiClient from "../../../api/apiClient"
import { booking } from "../../../api/apiRequests"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { DATA_TIME, DATA_TIME_2 } from "../../../utils/constants"
import CustomDatePicker from "../../components/CustomDatePicker"
import CourtDrawerWrapper from "./CourtDrawerWrapper"
import { navigate } from "@reach/router"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    fontFamily: ["DM Sans"].join(","),
  },
  titleRoot: {
    width: "97%",
    display: "flex",
    marginTop: "40px",
    marginBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15px",
    fontFamily: ["DM Sans"].join(","),
  },
  rootForm: {
    width: "100%",
  },
  rootMainForm: {
    paddingLeft: "15px",
    paddingRight: "15px ",
  },
  rootButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    bottom: "0",
    right: "0",
    justifyContent: "space-between",
    position: "absolute",
    marginBottom: "20px",
  },
  subButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: "25px",
  },
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
  typoHelperText: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  rootPrice: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  editRootButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    bottom: "0",
    right: "0",
    position: "absolute",
    marginBottom: "20px",
  },
  date: {
    "&.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
      border: "3px solid black",
      width: "100%",
      height: "10px",
      fontSize: "12px",
      fontFamily: "DM Sans",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "20px",
      marginRight: "80px",
      borderRadius: 10,
    },
  },
  startTimeStyles: {
    width: "100% !important",
    margin: "0px",
    marginRight: "10px",
  },
  addProductButton: {
    marginTop: "20px",
    background: "#45C165",
    border: 0,
    borderRadius: 5,
    fontFamily: "DM Sans",
    color: "white",
    height: 38,
    width: 200,
    fontWeight: "bold",
    padding: "0 10px",
    textTransform: "none",
  },
}))
function NewBooking({ close, isEdit }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const bookingFormState = useSelector(
    state => state.scheduleSlice?.bookingInfo
  )

  const userInfo = useSelector(state => state.userSlice?.user?.userInfo)
  const courtsState = useSelector(state => state.courtSettingsReducer.courtData)

  const [formErrors, setFormsErrors] = useState({})
  const [startTime, setStartTime] = useState([])
  const [endTime, setEndTime] = useState([])

  const [courtsData, setCourtsData] = useState([])
  const [drawerOptions, setDrawerOptions] = useState({
    open: false,
    content: "",
  })

  const handleClose = () => {
    setDrawerOptions({ open: false, content: "" })
    navigate("/venue/schedule")
  }

  const handleChange = (key, value) => {
    let temp = { ...formErrors }
    if (key == "startTime") {
      dispatch(
        actions.bookingFieldsInfo({
          key1: "bookingInfo",
          key2: "endTime",
          value: "",
        })
      )
    }
    delete temp[key]

    setFormsErrors({ ...temp })
    dispatch(
      actions.bookingFieldsInfo({ key1: "bookingInfo", key2: key, value })
    )
  }

  const addSlot = () => {
    const { isLoading, ...data } = bookingFormState
    dispatch(
      createBooking({
        body: {
          ...data,
          date: moment(bookingFormState.date).format("YYYY-MM-DD"),
        },
        params: { clubId: userInfo?.clubId },
      })
    )
    close()
  }

  const handleBookingEdit = () => {
    const { isLoading, ...data } = bookingFormState
    dispatch(
      updateBooking({
        params: { bookingId: bookingFormState.id, clubId: userInfo?.clubId },
        body: {
          ...data,
          date: moment(bookingFormState.date).format("YYYY-MM-DD"),
        },
      })
    )
    close()
  }

  const validateFields = () => {
    const temp = { ...bookingFormState }
    let err = {}
    Object.keys(temp).map(key => {
      if (temp[key] === "" && key != "isLoading") {
        err = { ...err, [key]: `Required*` }
      }
    })
    return err
  }

  const validations = () => {
    let errors = {}

    let err = validateFields()

    let { ...errorsList } = err

    if (Object.keys(errorsList).length > 0) {
      errors = { ...errors, ...errorsList }
    }

    if (Object.keys(errors).length === 0) {
      if (isEdit) {
        handleBookingEdit()
      } else {
        addSlot()
      }
    }
    setFormsErrors({ ...formErrors, ...errors })
  }

  const getAvailableSlots = async () => {
    try {
      let date = moment(bookingFormState.date).format("YYYY-MM-DD")
      if (!isEdit) {
        dispatch(
          actions.bookingFieldsInfo({
            key1: "bookingInfo",
            key2: "endTime",
            value: "",
          })
        )
        dispatch(
          actions.bookingFieldsInfo({
            key1: "bookingInfo",
            key2: "startTime",
            value: "",
          })
        )
      }
      const res = await apiClient(
        booking.getBookingAvailableSlots({
          params: {
            clubId: userInfo?.clubId,
            productId: bookingFormState.court,
            date,
          },
        })
      )
      if (isEdit) {
        let startTime = `${date}T${bookingFormState?.startTime}`
        let endTime = `${date}T${bookingFormState?.endTime}`
        let slots = [...res?.data?.data]
        slots.push({
          dateTime:
            startTime.split("T")[0] +
            "T" +
            startTime.split("T")[1].padStart(5, "0"),
        })
        slots.push({
          dateTime:
            endTime.split("T")[0] +
            "T" +
            endTime.split("T")[1].padStart(5, "0"),
        })

        // sort the array in ascending order of date and time
        slots = slots
          .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))
          .map((item, index) => ({
            label: moment(item?.dateTime).format("H:mm"),
            value: index,
          }))
        let index = slots.findIndex(
          item => item.label == bookingFormState.startTime
        )
        setStartTime(slots)
        setEndTime(slots.slice(index + 1))
      } else {
        let time = res?.data?.data.map((item, index) => ({
          label: moment(item?.dateTime).format("H:mm"),
          value: index,
        }))
        setStartTime(time)
        setEndTime(time)
      }
    } catch (err) {
      console.log("err", err)
    }
  }

  useEffect(() => {
    if (bookingFormState.date && bookingFormState.court) {
      getAvailableSlots()
    }
  }, [bookingFormState.date, bookingFormState.court])
  useEffect(() => {
    if (courtsState) {
      const temp = []
      courtsState?.map(item => {
        if (item?.status?.label == "Active") {
          temp.push({
            label: item?.courtName,
            id: item?.id,
          })
        }
      })
      setCourtsData(temp)
    }
  }, [courtsState])

  // if (courtsState.length === 0) {
  //   return (
  //     <Link
  //       to="/venue/schedule?add_product=true"
  //       style={{ textDecoration: "none" }}
  //     >
  //       <Button
  //         variant="contained"
  //         style={{
  //           marginTop: "20px",
  //           background: "#45C165",
  //           border: 0,
  //           borderRadius: 5,
  //           fontFamily: "DM Sans",
  //           color: "white",
  //           height: 38,
  //           width: 130,
  //           fontWeight: "bold",
  //           padding: "0 10px",
  //           textTransform: "none",
  //         }}

  //       >
  //         Add Product
  //       </Button>
  //     </Link>
  //   )
  // }

  return (
    <div className={classes.root}>
      {courtsState.length === 0 ? (
        <>
          <div className={classes.titleRoot}>
            <Typography
              className={classes.typoTitle}
              style={{ margin: "13px" }}
            >
              {isEdit ? "Edit Booking" : "New booking"}
            </Typography>
            <IconButton
              style={{ borderRadius: 0, backgroundColor: "transparent" }}
              onClick={close}
            >
              <CloseIcon style={{ margin: "13px" }} />
            </IconButton>
          </div>

          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                className={classes.typoHelperText}
                style={{ margin: "13px" }}
              >
                Please add at least one product to start creating bookings.
              </Typography>
              <Link
                to="/venue/schedule?add_product=true"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{
                    marginTop: "20px",
                    background: "#45C165",
                    border: 0,
                    borderRadius: 5,
                    fontFamily: "DM Sans",
                    color: "white",
                    height: 38,
                    width: 130,
                    fontWeight: "bold",
                    padding: "0 10px",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    setDrawerOptions({
                      open: true,
                      content: "NewCourt",
                    })
                  }
                >
                  Add Product
                </Button>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.titleRoot}>
            <Typography
              className={classes.typoTitle}
              style={{ margin: "13px" }}
            >
              {isEdit ? "Edit Booking" : "New booking"}
            </Typography>
            <IconButton
              style={{ borderRadius: 0, backgroundColor: "transparent" }}
              onClick={close}
            >
              <CloseIcon style={{ margin: "13px" }} />
            </IconButton>
          </div>
          <div className={classes.rootForm}>
            <div className={classes.rootMainForm}>
              <DrawerSelect
                errors={formErrors?.court}
                onChange={value => {
                  handleChange("court", value.id)
                }}
                value={courtsData.find(
                  item => item.id === bookingFormState?.court
                )}
                placeHolder="Choose product"
                customStylesForLabel={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "14px 0px 10px 0px",
                }}
                data={courtsData}
                label="Choose product"
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box style={{ marginLeft: "10px" }}>
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        margin: "14px 0px 10px 0px",
                      }}
                    >
                      Date & Time
                    </Typography>
                    <CustomDatePicker
                      handleChange={handleChange}
                      value={
                        bookingFormState.date
                          ? moment(bookingFormState.date)
                          : moment(new Date())
                      }
                      disablePast={true}
                    />
                  </Box>
                </Grid>
                {/* <BasicDatePicker
              sx={{
                ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                  marginTop: "25px",
                  borderColor: "transparent",
                },
              }}
              minDate={isEdit ? moment(bookingFormState?.date) : moment()}
              value={
                bookingFormState.date ? moment(bookingFormState.date) : null
              }
              onChange={date => {
                handleChange("date", date)
              }}
            /> */}
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      direction: "column",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <DrawerSelect
                      isDisabled={
                        !bookingFormState?.court || !bookingFormState?.date
                      }
                      customStyles={{ width: "100%" }}
                      placeHolder="Time"
                      data={startTime}
                      errors={formErrors?.startTime}
                      onChange={value => {
                        handleChange("startTime", value.label)
                        let index = startTime.findIndex(
                          item => item.label == value.label
                        )
                        setEndTime(startTime.slice(index + 1))
                      }}
                      value={
                        startTime && bookingFormState?.startTime
                          ? startTime.find(
                              item => item.label == bookingFormState?.startTime
                            )
                          : {}
                      }
                      classesForSelect={classes.startTimeStyles}
                      label="&nbsp;&nbsp;"
                    />
                    <Typography style={{ marginTop: "20px" }}>-</Typography>
                    <DrawerSelect
                      isDisabled={
                        !bookingFormState.court || !bookingFormState.date
                      }
                      placeHolder=" Time"
                      data={endTime}
                      errors={formErrors?.endTime}
                      onChange={value => {
                        handleChange("endTime", value.label)
                      }}
                      value={
                        bookingFormState?.endTime
                          ? endTime.find(
                              item => item.label == bookingFormState?.endTime
                            )
                          : {}
                      }
                      label="&nbsp;&nbsp;"
                    />
                  </div>
                </Grid>
              </Grid>

              <div className={classes.rootButton}>
                <div className={classes.subButton}>
                  {/* {selectedCourt && (
                <>
                  <Typography>Order Value</Typography>
                  <Typography>{`${selectedCourt?.price} ${selectedCourt?.currency}`}</Typography>
                </>
              )} */}
                  <DrawerButton
                    text={isEdit ? "Edit" : "Create"}
                    handleOnClick={() => validations()}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <CourtDrawerWrapper
        handleClose={handleClose}
        open={drawerOptions.open}
        content={drawerOptions.content}
      />
    </div>
  )
}

export default NewBooking
